import { IServicePageResponse } from '../../../api.interface'

export default <IServicePageResponse>{
  serviceType: 'handyman',
  formId: 'DK%20Handyman',
  title: 'Handyman',
  subTitle: 'Skal der hamres?',
  icon: '/icons/maintenance.svg',
  image: '/assets/images/sunrise/handyman_supermercat.png',
  theme: 'sunrise-handyman',
  sections: [
    {
      sectionId: 'hero',
      label: 'Handyman',
      title: 'Skal der hamres?',
      description:
        'For mange tommelfingre? Du er kun et par klik fra at få hængt de der nye lamper op. Lige! Consider it done. Vi giver dig adgang til en bred vifte af handyfolk, der kan det hele og har hænderne skruet rigtigt på. Og det gælder også el og vvs.',
      image: '/assets/images/sunrise/handyman_supermercat.png',
      icon: '/icons/maintenance.svg',
      buttonText: 'Få et tilbud',
    },
    {
      sectionId: 'options',
      title: 'Vores handymen kan hjælpe med alt ',
      description: '',
      optionsItems: [
        {
          icon: '/assets/images/services/handyman/service_1.svg',
          title: 'Samling af møbler',
          description: 'Vore handyfolk kan samle møbler uden at blive skilt.',
        },
        {
          icon: '/assets/images/services/handyman/service_2.svg',
          title: 'Reparationer',
          description: 'Vi kan reparere alt undtagen et knust hjerte. Medmindre du virkelig har brug for det <3.',
        },
        {
          icon: '/assets/images/services/handyman/service_3.svg',
          title: 'Ophængning',
          description: 'Er hammer og søm på ferie? Vi hænger gerne både kunst, fladskærme og dartskiver op.',
        },
        {
          icon: '/assets/images/services/handyman/service_4.svg',
          title: 'Det tunge',
          description:
            'Skal der flyttes rundt på kontoret? Eller har I bare brug for muskelstyrke til nogle tunge ting? Den tager vi.',
        },
      ],
    },
    {
      sectionId: 'journey',
      title: 'Hvordan virker det?',
      description: '',
      journeyItems: [
        {
          icon: '/assets/images/services/handyman/journey_1.svg',
          title: 'Hvad har du brug for?',
          description: 'Vær så præcis, du kan, om hvad og hvornår.',
        },
        {
          icon: '/assets/images/services/handyman/journey_2.svg',
          title: 'Få et tilbud',
          description: 'Få en fast pris og en dato for, hvornår vores handyman kan klare ærterne.',
        },
        {
          icon: '/assets/images/services/handyman/journey_3.svg',
          title: 'Ja tak!',
          description: 'Så let er det. Læn dig tilbage og gør det, du er bedre til.',
        },
      ],
    },
    {
      sectionId: 'benefits',
      title: 'Hvorfor skal du vælge en handyman gennem Good Monday?',
      description: '',
      image: '/assets/images/services/handyman/benefits_illustration.svg',
      benefitsItems: [
        {
          icon: 'eva eva-calendar-outline',
          title: 'Flexibelt',
          description: 'Vi finder et tidspunkt, der passer ind i dine planer.',
        },
        {
          icon: 'eva eva-flash-outline',
          title: 'Lynsnart',
          description: 'Vi har et kæmpe netværk og kan rykke hurtigt til hasteopgaver.',
        },
        {
          icon: '',
          title: 'Noget for pengene',
          description: 'De bedste og de hurtigste. Høj kvalitet for rimelige priser.',
        },
      ],
    },
    {
      sectionId: 'cta',
      title: 'Vidste du at et abonnement på en handyman kan forlænge mange tings levetid?',
      description: '',
      image: '/assets/images/services/cta_illustration.svg',
      buttonText: 'Få et tilbud',
    },
  ],
}
